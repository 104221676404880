import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import {
  BodyNormal,
  BodySmall,
  StatementV2,
  StepHeaderBar,
  StepTab,
  StrategicSummary,
  Subtitle2,
} from 'components/shared';
import { colors, colorsv2 } from 'constants/colors';
import { polling } from 'constants/index';
import {
  KeyInsightFragment,
  KeyInsightsQueryVariables,
  PostItGroupFragment,
  Sort,
  StatementFragment,
  Step,
  SubStep,
  TheWhyPageFragment,
  useKeyInsightsQuery,
  usePostItGroupsWithCardsQuery,
  useStatementsQuery,
  useStrategyQuery,
  useTheDecisionPageQuery,
  useTheDecisionPageUpdateMutation,
  useTheWhyPagesQuery,
} from 'data/graphql/generated';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { GradientBorderCard } from '../../components/shared/GradientCard';
import { PositioningTabKebabCase } from '../../types';
// import { getStatementText } from '../../utils/brandPositioning';
import { Navbar } from './Navbar';
import _, { kebabCase } from 'lodash';
import { StyledBulletPointWithText } from 'components/Positioning/TheWhy';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { LoadingComponent } from 'components/Loading';
import useDesktop from 'hooks/useDesktop';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { TextAreaInput } from 'components/shared/TextAreaInput';
import { UnderlineTextFieldContainer } from 'components/shared/UnderlineTextField';
import { BulletPointList } from 'components/shared/BulletPointList';

// const ReasonsToBelieveUL = styled.ul`
//   margin: 0px;
//   padding-inline-start: 30px;
// `;

const PageContent = styled.div<{ height: number; isActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: top;
  justify-content: top;
  width: 100%;
  height: ${({ height }) => height - 224}px;
  overflow-x: auto;
  padding: 20px 20px;
  background: ${({ isActive }) =>
    isActive ? colorsv2.bg.surfaceBase2 : colors.greyLight};
  background-image: url(/Wave@2x.png);
  background-size: 1377px 944px;
  background-repeat: no-repeat;
  background-position: center 64px;

  @media ${device.tabletMax} {
    justify-content: center;
    align-items: center;
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  gap: 15px;

  @media ${device.tabletMax} {
    flex-direction: column;
    padding: 10px;
  }

  @media (min-width: 1463px) {
    justify-content: center;
  }
`;

const StyledStatementV2 = styled(StatementV2)`
  background: transparent;
  padding: 10px;
`;

const SubtitleWrapper = styled.div`
  pointer-events: none;
  cursor: default;
  > p {
    display: inline;
  }
`;

const CardWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px;
  margin-right: 10px;

  @media ${device.desktop} {
    min-width: 220px;
    max-width: 391px;
  }

  @media ${device.tabletMax} {
    background: ${colors.white};
    border: 1px solid ${colors.black30};
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 451px;
  }
`;

const Steps = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  @media ${device.tabletMax} {
    margin-top: 10px;
  }
`;

const LoadingWrapper = styled(LoadingComponent)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.black30};
  border-radius: 3px;
  text-align: left;
  padding: 15px;
  margin-top: 20px;
  display: flex;
  width: auto;

  @media ${device.tabletMax} {
    flex-direction: column;
    width: 100%;
    padding: 0;
    background: transparent;
    border: none;
    justify-content: center;
    align-items: center;
  }
`;

const StepTabWrapper = styled(StepTab)`
  color: ${colors.purple};
  background: ${({ active }) => (active ? colors.purple : colors.purple10)};
  p {
    color: ${({ active }) => (active ? colors.white : colors.black)};
  }

  &:hover {
    background: ${({ active }) => (active ? colors.purple : colors.purple)};
    p {
      color: ${({ active }) => (active ? colors.white : colors.white)};
    }
  }
`;

const EmptyWrapper = styled.div`
  width: 256px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;

  @media ${device.desktop} {
    min-width: 220px;
  }

  @media ${device.tabletMax} {
    width: 100%;
  }
`;

export const StyledBulletPointList = styled(BulletPointList) <{ disabled: boolean }>`
  ${UnderlineTextFieldContainer} {
    align-items: baseline;

    > textarea {
      font-size: 18px;
      color: ${({ disabled }) => (disabled ? colors.greyDark : 'initial')};
    }

    color: ${colors.greyDark};
  }
`;

enum TabPartType {
  Learnings = 'learnings',
  Decision = 'decision',
}

export const StepHeader = styled(StepHeaderBar) <{ isDesktop: boolean }>`
  display: block;
  padding: 15px 20px 0px;
  border-bottom: 0.5px solid ${colors.greyMedium};
  margin-top: ${({ isDesktop }) => (isDesktop ? '110px' : '155px')};
  min-height: auto;
  overflow: visible;
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  positioningTab: PositioningTabKebabCase;
  competitorId: string;
  decisionTab: string;
}

export const Summary: FC = () => {
  const {
    drugId,
    strategyId,
    competitorId,
    decisionTab,
  } = useParams<URLParams>();
  const [selectedTab, setSelectedTab] = useState<TabPartType>(
    TabPartType.Learnings
  );
  const history = useHistory();
  const { path } = useRouteMatch();
  const isDesktop = useDesktop();

  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);

  const [bulletPointsLocalState, setBulletPointsLocalState] = useState<
    string[] | string
  >([]);

  const getHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const height = getHeight();
  // const [reasonsToBelieveText, setReasonsToBelieveText] = useState<string>('');

  const setPageState = (state: string) => {
    const generatedPath = generatePath(path, {
      drugId,
      strategyId,
      state: state.toLowerCase(),
    });
    history.replace(generatedPath);
  };

  useEffect(() => {
    if (!decisionTab || decisionTab === undefined) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCase(
          SubStep.TheDecide
        )}/${selectedTab}`
      );
    }
  }, [drugId, history, selectedTab, decisionTab, strategyId]);

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
  } = useStrategyQuery({
    variables: { id: +strategyId },
  });

  // const {
  //   data: theWhyPageData,
  //   loading: theWhyPageLoading,
  //   startPolling: theWhyPageStartPolling,
  //   stopPolling: theWhyPageStopPolling,
  //   error: theWhyPageError,
  // } = useTheWhyPageWithStatementsQuery({
  //   variables: {
  //     where: {
  //       strategyId: +strategyId,
  //       focus: true,
  //     },
  //   },
  //   fetchPolicy: 'cache-and-network',
  // });

  const keyInsightsQueryVars: KeyInsightsQueryVariables = {
    where: { strategyId: Number(strategyId) },
    orderBy: { createdAt: Sort.Desc },
  };

  const {
    data: keyInsightsData,
    startPolling: keyInsightsStartPolling,
    stopPolling: keyInsightsStopPolling,
    loading: keyInsightsDataLoading,
  } = useKeyInsightsQuery({
    variables: {
      ...keyInsightsQueryVars,
    },
  });

  const { items: insightGroups } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
        preset: false,
      },
      orderBy: {
        id: Sort.Asc,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho,
    }
  );

  const { data: groupsWithCardsData } = usePostItGroupsWithCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
        title: `What are the brand position that matter to key insights?`,
        preset: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const whatAreTheBrandPositionGroup =
    groupsWithCardsData?.postItGroups?.items[0];

  const cardsByAlignmentOrder = useMemo(
    () => whatAreTheBrandPositionGroup?.cards || [],
    [whatAreTheBrandPositionGroup?.cards]
  );

  const unsortedGroups = useMemo(
    () =>
      cardsByAlignmentOrder
        .filter((e) => e.include === true)
        .map((card) => {
          const group = keyInsightsData?.keyInsights?.items.find(
            (group) => group.text === card.title
          );

          if (group) {
            return {
              ...group,
              postItGroupId: card.postItGroupId,
            };
          }
          return null;
        })
        .filter((v) => !!v) as KeyInsightFragment[],
    [cardsByAlignmentOrder, keyInsightsData?.keyInsights?.items]
  );

  const unsortedWhyGroups = useMemo(() => {
    return cardsByAlignmentOrder
      .filter((card) => card.include)
      .map((card) => {
        const group = insightGroups.find((group) => group.title === card.title);
        return group ? { ...group, postItGroupId: card.postItGroupId } : null;
      })
      .filter(Boolean) as PostItGroupFragment[];
  }, [cardsByAlignmentOrder, insightGroups]);

  const groups = useMemo(() => _.orderBy(unsortedGroups, ['pos'], ['desc']), [
    unsortedGroups,
  ]);

  const {
    data: TheWhoStatements,
    startPolling: TheWhoStatementsStartPolling,
    stopPolling: TheWhoStatementsStopPolling,
    loading: TheWhoStatementsLoading,
  } = useStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.Positioning,
        substep: [SubStep.TheWho],
      },
    },
    nextFetchPolicy: 'cache-and-network',
  });

  const filterWhoItsForStatement = useMemo(
    () =>
      groups
        .map((g) =>
          TheWhoStatements?.statements?.items.find(
            (statement) => statement.postItGroupId === g.theWhoPageId
          )
        )
        .filter(Boolean) as StatementFragment[],
    [groups, TheWhoStatements?.statements?.items]
  );

  const {
    data: TheWhyStatements,
    startPolling: TheWhyStatementsStartPolling,
    stopPolling: TheWhyStatementsStopPolling,
    loading: TheWhyStatementsLoading,
  } = useStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.Positioning,
        substep: [SubStep.TheWhy],
      },
    },
    nextFetchPolicy: 'cache-and-network',
  });

  const {
    data: WhyPages,
    loading: WhyPagesLoading,
    startPolling: WhyPagesStartPolling,
    stopPolling: WhyPagesStopPolling,
  } = useTheWhyPagesQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        focus: true,
      },
    },
    nextFetchPolicy: 'cache-and-network',
  });

  const {
    data: theDecisionData,
    loading: theDecisionLoading,
    // startPolling: WhyPagesStartPolling,
    // stopPolling: WhyPagesStopPolling,
  } = useTheDecisionPageQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        drugId: +drugId,
      },
    },
    nextFetchPolicy: 'network-only',
  });

  const theDecision = theDecisionData?.theDecisionPage;

  const [updateTheDecisionPage] = useTheDecisionPageUpdateMutation();

  async function updateDecision(
    type: 'whoItsFor' | 'insight' | 'whatItIs' | 'benefit' | 'reasonToBelieve',
    text: string[] | string
  ) {
    if (!theDecision) return;

    if (theDecision && type && text) {
      try {
        await updateTheDecisionPage({
          variables: {
            id: theDecision.id,
            data: {
              [type]: text,
            },
          },
        });
      } catch (error) {
        console.error(error);
        alert('Something went wrong');
      }
    }
  }

  const WhatItIsStatements = TheWhyStatements?.statements?.items.filter(
    (s) => s?.title.toLowerCase() === 'what it is'.toLowerCase()
  );
  const BenefitsStatements = TheWhyStatements?.statements?.items.filter(
    (s) => s?.title.toLowerCase() === 'benefits'.toLowerCase()
  );
  const ReasonsToBelieveStatments = TheWhyStatements?.statements?.items.filter(
    (s) => s?.title.toLowerCase() === 'reasons to believe'.toLowerCase()
  );

  const createStatementCards = (
    statements: StatementFragment[],
    WhyPages: TheWhyPageFragment[]
  ) => {
    return statements
      ?.map((statement) => {
        const group = WhyPages?.find(
          (group) => group.id === statement.theWhyPageId
        );
        return group ? { ...statement } : null;
      })
      .filter(Boolean);
  };

  const WhatItIsStatementsCards = useMemo(
    () =>
      createStatementCards(
        WhatItIsStatements || [],
        WhyPages?.theWhyPages?.items || []
      ) as StatementFragment[],
    [WhatItIsStatements, WhyPages]
  );

  const BenefitsStatementsCards = useMemo(
    () =>
      createStatementCards(
        BenefitsStatements || [],
        WhyPages?.theWhyPages?.items || []
      ) as StatementFragment[],
    [BenefitsStatements, WhyPages]
  );

  const ReasonsToBelieveCards = useMemo(
    () =>
      createStatementCards(
        ReasonsToBelieveStatments || [],
        WhyPages?.theWhyPages?.items || []
      ) as StatementFragment[],
    [ReasonsToBelieveStatments, WhyPages]
  );

  // filter learning statements that are not included in insights
  const useFilterStatements = (
    statements: StatementFragment[],
    unsortedGroups: PostItGroupFragment[]
  ) =>
    useMemo(
      () =>
        unsortedGroups
          .map((g) => {
            const group = statements.find(
              (statement) => statement.theWhyPageId === g.theWhyPageId
            );
            return group ? { ...group } : null;
          })
          .filter((v) => !!v) as StatementFragment[],
      [statements, unsortedGroups]
    );

  const filterReasonToBelieveCards = useFilterStatements(
    ReasonsToBelieveCards,
    unsortedWhyGroups
  );

  const filterBenefitsCard = useFilterStatements(
    BenefitsStatementsCards,
    unsortedWhyGroups
  );

  const filterFrameOfReference = useFilterStatements(
    WhatItIsStatementsCards,
    unsortedWhyGroups
  );

  useEffect(() => {
    // theWhyPageStartPolling(polling.default);
    startStrategyPolling(polling.default);
    keyInsightsStartPolling(polling.default);
    TheWhoStatementsStartPolling(polling.default);
    TheWhyStatementsStartPolling(polling.default);
    WhyPagesStartPolling(polling.default);
    return () => {
      // theWhyPageStopPolling();
      stopStrategyPolling();
      keyInsightsStopPolling();
      TheWhoStatementsStopPolling();
      TheWhyStatementsStopPolling();
      WhyPagesStopPolling();
    };
  }, [
    TheWhoStatementsStartPolling,
    TheWhoStatementsStopPolling,
    TheWhyStatementsStartPolling,
    TheWhyStatementsStopPolling,
    WhyPagesStartPolling,
    WhyPagesStopPolling,
    keyInsightsStartPolling,
    keyInsightsStopPolling,
    startStrategyPolling,
    stopStrategyPolling,
  ]);

  const DecisionStatementInput = ({
    statementText,
    loading,
    type,
    placeholder,
    disabled,
    title,
  }: {
    statementText: any;
    loading: boolean;
    type:
    | 'reasonToBelieve'
    | 'insight'
    | 'whatItIs'
    | 'benefit'
    | 'whoItsFor';
    placeholder: string;
    disabled: boolean;
    title: string;
  }) => {
    const [text, setText] = useState(statementText || '');

    if (loading) return <></>;

    return (
      <GradientWrapper>
        <BodySmall color={colors.greyDark}>{title}</BodySmall>
        <TextAreaInput
          style={{ marginTop: 5 }}
          disabled={disabled}
          $borderless
          maxLength={200}
          placeholder={placeholder}
          onBlur={() => updateDecision(type, text)}
          rows={1}
          value={text}
          onChange={async (e) => {
            const target = e.target as HTMLTextAreaElement;
            setText(target.value);
          }}
        />
      </GradientWrapper>
    );
  };

  useEffect(() => {
    if (theDecision?.reasonToBelieve) {
      setBulletPointsLocalState(
        theDecision?.reasonToBelieve.filter(
          (item): item is string => item !== null
        )
      );
    }

    // eslint-disable-next-line
  }, [theDecision?.reasonToBelieve]);

  const KeyInsightCard: FC<{
    title: string;
    subtitle?: string;
    emptySubtitle: string;
    tabIndex: number;
    isEmpty: boolean;
    theWhyPages?: TheWhyPageFragment[] | undefined;
    statements?: StatementFragment[] | undefined;
    groups?: KeyInsightFragment[] | undefined;
  }> = ({
    title,
    subtitle,
    tabIndex,
    statements,
    groups,
    isEmpty,
    emptySubtitle,
  }) => {
      if (isEmpty) {
        const cardTitle = `No ${title} yet`;
        const link =
          tabIndex === 2 ? kebabCase(SubStep.TheWho) : kebabCase(SubStep.TheWhy);
        return (
          <EmptyWrapper>
            <BodySmall color={colors.greyDark}>
              {title} {subtitle}
            </BodySmall>
            <PostItsEmpty
              style={{
                border: `1px solid ${colors.greyLight}`,
              }}
              title={cardTitle}
            >
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                {emptySubtitle}
              </BodySmall>

              <Link
                to={`/d/${drugId}/strategy/${strategyId}/3_1/${link}`}
                style={{
                  color: colors.greyDark,
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {' '}
                Part {tabIndex}{' '}
              </Link>
            </PostItsEmpty>
          </EmptyWrapper>
        );
      }

      if (statements) {
        return (
          <CardWrapper>
            <BodyNormal color={colors.greyDark}>
              {title} {subtitle}
            </BodyNormal>

            {/* eslint-disable-next-line array-callback-return */}
            {statements.map((statement) => {
              const isWhatItIsStatement =
                statement.title.toLowerCase() === 'what it is'.toLowerCase();
              const isBenefitsStatement =
                statement.title.toLowerCase() === 'benefits'.toLowerCase();
              const isReasonsToBelieveStatement =
                statement.title.toLowerCase() ===
                'reasons to believe'.toLowerCase();

              // what it is statement
              if (statement?.substep === SubStep.TheWhy && isWhatItIsStatement) {
                const whatItIsStatement =
                  statement.title.toLowerCase() === 'what it is'.toLowerCase();
                const whatItIsStatementText =
                  whatItIsStatement &&
                  drugName +
                  ' is ' +
                  (statement.text[0] || 'a type of drug or device category');

                return (
                  <StyledStatementV2
                    title=""
                    collaboration={isLead ? statement?.collaboration : undefined}
                    showAligned={false}
                  >
                    <SubtitleWrapper>
                      <BodySmall>{whatItIsStatementText}</BodySmall>
                    </SubtitleWrapper>
                  </StyledStatementV2>
                );
              }

              // benefits statement
              if (statement?.substep === SubStep.TheWhy && isBenefitsStatement) {
                const statementText1 =
                  statement?.text[0] || 'provides a functional benefit';
                const statementText2 =
                  statement?.text[1] || 'an emotional benefit.';
                const whatItIsStatementText = `${drugName} ${statementText1} so that ${statementText2}`;

                return (
                  <StyledStatementV2
                    title=""
                    collaboration={isLead ? statement?.collaboration : undefined}
                    showAligned={false}
                  >
                    <SubtitleWrapper>
                      <BodySmall>{whatItIsStatementText}</BodySmall>
                    </SubtitleWrapper>
                  </StyledStatementV2>
                );
              }

              // reasons to believe statement
              if (
                statement?.substep === SubStep.TheWhy &&
                isReasonsToBelieveStatement
              ) {
                const statementText = statement?.text.filter((v) => !!v);

                return (
                  <StyledStatementV2
                    title=""
                    collaboration={isLead ? statement?.collaboration : undefined}
                    showAligned={false}
                  >
                    <SubtitleWrapper>
                      {!!statementText?.length ? (
                        statementText?.map((text, idx) => (
                          <StyledBulletPointWithText
                            text={text}
                            textStyle={{
                              backgroundColor: 'transparent',
                              wordBreak: 'break-word',
                              hyphens: 'auto',
                            }}
                            color={colors.greyDark}
                          />
                        ))
                      ) : (
                        <StyledBulletPointWithText
                          text={'A key reason to believe'}
                          textStyle={{
                            backgroundColor: 'transparent',
                            wordBreak: 'break-word',
                            hyphens: 'auto',
                          }}
                          color={colors.greyDark}
                        />
                      )}
                    </SubtitleWrapper>
                  </StyledStatementV2>
                );
              }

              // who it is for statement
              if (statement?.substep === SubStep.TheWho) {
                const whoItIsStatement =
                  statement.title.toLowerCase() === `who it's for`.toLowerCase();

                if (!whoItIsStatement) return <></>;

                const statementText1 = statement?.text[0] || 'the brand target';
                const statementText2 =
                  statement?.text[1] || 'want to overcome, or achieve something';
                const statementText = `${drugName} is for ${statementText1} who ${statementText2}`;

                return (
                  <StyledStatementV2
                    title=""
                    collaboration={isLead ? statement?.collaboration : undefined}
                    showAligned={false}
                  >
                    <SubtitleWrapper>
                      <BodySmall>{statementText}</BodySmall>
                    </SubtitleWrapper>
                  </StyledStatementV2>
                );
              }
            })}
          </CardWrapper>
        );
      }

      return (
        <CardWrapper>
          <BodyNormal color={colors.greyDark}>{title}</BodyNormal>
          {groups?.map((group) => {
            return (
              <StyledStatementV2
                title=""
                collaboration={isLead ? group?.collaboration : undefined}
                showAligned={false}
              >
                <SubtitleWrapper>
                  <BodySmall>{group?.text}</BodySmall>
                </SubtitleWrapper>
              </StyledStatementV2>
            );
          })}
        </CardWrapper>
      );
    };

  const drugName = strategyData?.strategy?.drug?.name || '';
  // const whoItsForStatement = theWhyPageData?.theWhyPage?.StatementParent;
  // const statements = theWhyPageData?.theWhyPage?.Statement || [];

  // const noStatements = !whoItsForStatement && !statements;
  // const combinedStatements = [...statements, whoItsForStatement].filter(
  //   Boolean
  // ) as StatementFragment[];

  return (
    <>
      <StrategicSummary />

      <Navbar
        drugId={drugId}
        strategyId={strategyId}
        competitorId={competitorId}
        currentPositioningTab="the-decide"
      />
      <StepHeader isDesktop={isDesktop}>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          Discuss what you've learnt from research or feedback from patients and
          physicians
        </Subtitle2>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            marginBottom: '15px',
          }}
        >
          <BodyNormal color={colors.greyDark} style={{ display: 'inline' }}>
            Leads should review the discussion and finalise the positioning on
            the 'Decision' tab
          </BodyNormal>
          <Steps>
            <StepTabWrapper
              active={decisionTab === TabPartType.Learnings}
              text="Learnings"
              onClick={() => {
                setSelectedTab(TabPartType.Learnings);
                setPageState(TabPartType.Learnings);
              }}
            />
            <StepTabWrapper
              active={decisionTab === TabPartType.Decision}
              text="Decision"
              onClick={() => {
                setSelectedTab(TabPartType.Decision);
                setPageState(TabPartType.Decision);
              }}
            />
          </Steps>
        </div>
      </StepHeader>

      <PageWrapper>
        {decisionTab === TabPartType.Decision && (
          <PageContent
            height={height}
            isActive={decisionTab === TabPartType.Decision}
          >
            <ErrorWrapper
              isLoading={theDecisionLoading}
              errors={[]}
              dataMissing={false}
            >
              <div
                style={{ width: '100%', display: 'flex', position: 'relative' }}
              >
                <DecisionStatementInput
                  title="Insight"
                  statementText={theDecision?.insight}
                  loading={theDecisionLoading}
                  type="insight"
                  placeholder={!isLead ? 'Not defined yet' : 'Type the insight'}
                  disabled={!isLead}
                />
              </div>
              <div
                style={{ width: '100%', display: 'flex', position: 'relative' }}
              >
                <DecisionStatementInput
                  statementText={theDecision?.whoItsFor}
                  loading={theDecisionLoading}
                  type="whoItsFor"
                  title="Who It's For"
                  placeholder={
                    !isLead ? 'Not defined yet' : "Type the who it's for"
                  }
                  disabled={!isLead}
                />
              </div>
              <DecisionStatementInput
                statementText={theDecision?.whatItIs}
                loading={theDecisionLoading}
                title="Frame of Reference (What it is)"
                type="whatItIs"
                placeholder={
                  !isLead ? 'Not defined yet' : 'Type the frame of reference'
                }
                disabled={!isLead}
              />

              <DecisionStatementInput
                title="Benefits (Emotional and Functional)"
                statementText={theDecision?.benefit}
                loading={theDecisionLoading}
                type="benefit"
                placeholder={!isLead ? 'Not defined yet' : 'Type the benefit'}
                disabled={!isLead}
              />
              {/* )} */}
              <GradientWrapper>
                <BodySmall color={colors.greyDark}>
                  Reasons to believe
                </BodySmall>
                <StyledBulletPointList
                  style={{
                    marginTop: 5,
                    pointerEvents: !isLead ? 'none' : 'all',
                  }}
                  disabled={!isLead}
                  onUpdate={(value) => {
                    if (value) setBulletPointsLocalState(value);
                  }}
                  handleBlur={() => {
                    const valueToUpdate = Array.isArray(bulletPointsLocalState) 
                      ? bulletPointsLocalState
                      : [bulletPointsLocalState].filter(Boolean);
                    updateDecision('reasonToBelieve', valueToUpdate);
                  }}
                  preventAutoFocus
                  statement={theDecision?.reasonToBelieve as string[]}
                  bulletPoint={isLead}
                  placeholder={
                    !isLead ? 'Not defined yet' : 'Type the reason to believe'
                  }
                />
              </GradientWrapper>
            </ErrorWrapper>
          </PageContent>
        )}

        {/* Learnings Tab */}
        {decisionTab === TabPartType.Learnings && (
          // theWhyPageLoading ||

          <LoadingWrapper
            isLoading={
              WhyPagesLoading ||
              TheWhoStatementsLoading ||
              TheWhyStatementsLoading ||
              keyInsightsDataLoading ||
              !filterWhoItsForStatement ||
              !filterFrameOfReference ||
              !filterBenefitsCard ||
              !filterReasonToBelieveCards ||
              !strategyData
            }
          >
            <Container>
              <KeyInsightCard
                title="Insight"
                emptySubtitle="Leads should choose which to include in"
                tabIndex={2}
                groups={groups || []}
                isEmpty={groups?.length === 0}
              />
              <KeyInsightCard
                title="Who It's for"
                emptySubtitle="Leads should add these to insights in"
                tabIndex={3}
                statements={filterWhoItsForStatement || []}
                isEmpty={filterWhoItsForStatement?.length === 0}
              />
              <KeyInsightCard
                title="Frame of Reference"
                emptySubtitle="Leads should choose which to focus on in"
                tabIndex={3}
                subtitle="(What it is)"
                statements={filterFrameOfReference || []}
                isEmpty={filterFrameOfReference?.length === 0}
              />
              <KeyInsightCard
                title="Benefits"
                emptySubtitle="Leads should choose which to focus on in"
                tabIndex={3}
                subtitle="(Emotional and Functional)"
                statements={filterBenefitsCard || []}
                isEmpty={filterBenefitsCard?.length === 0}
              />
              <KeyInsightCard
                title="Reasons to Believe"
                emptySubtitle="Leads should choose which to focus on in"
                tabIndex={3}
                statements={filterReasonToBelieveCards || []}
                isEmpty={filterReasonToBelieveCards?.length === 0}
              />
            </Container>
          </LoadingWrapper>
        )}
      </PageWrapper>
    </>
  );
};

const GradientWrapper: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <GradientBorderCard
      style={{
        width: '100%',
        display: 'flex',
        maxWidth: '640px',
        margin: '0 auto',
      }}
    >
      <div>{children}</div>
    </GradientBorderCard>
  );
};
