import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BodyNormal, ButtonLabel, ButtonRound, Icon } from 'components/shared';
import { colors } from 'constants/colors';
import { PostItCardFragment, Role } from 'data/graphql/generated';
import styled from 'styled-components';
import { verifyUserRole } from 'utils/verifyUserRole';

// START: styled-components
const Card = styled.div<{ active: boolean, overlay: boolean, disabled: boolean }>`
  align-items: center;
  display: flex;
  padding: 10px;
  position: relative;
  width: 100%;
  z-index: -1;

  opacity: ${({ disabled, overlay }) => (disabled || overlay ? 0.7 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  visibility: ${({ active }) => (active ? 'hidden' : 'visible')};
`;

const CardBody = styled.div<{ focused: boolean, overlay: boolean; }>`
  align-items: center;
  border: 0.5px solid ${colors.black30};
  border-radius: 4px;
  background-color: ${colors.white};
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 10px;
  width: 100%;
`;

const CardGrip = styled.div<{ active?: boolean }>`
  cursor: ${({ active }) => (active ? 'grabbing' : 'grab')};
`;

const CardActions = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  right: -5px;
  top: -5px;
`;

const CardDeleteConfirm = styled.div<{ show: boolean }>`
  align-items: center;
  background-color: ${colors.white};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  width: 100%;

  p, button {
    font-size: 14px !important;
  }
`;

const CardDeleteConfirmButtons = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
`;

const CardDeleteButton = styled(ButtonRound)`
`;

const TextOnly = styled.div`
  align-items: center;
  display: flex;
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: auto;
  line-height: 1.5;
  min-height: 25px;
  color: ${colors.black};
  padding-left: 2px;
`;

const TextareaWrapper = styled.div`
  width: 100%;
`;

const Textarea = styled.textarea<{ focused: boolean }>`
  background: transparent;
  border: 0.5px solid ${({ focused }) => (focused ? colors.blue : '#fff' )};
  color: ${colors.black};
  display: block;
  font-family: ABCFavorit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  height: 0;
  min-height: 25px;
  resize: none;
  outline: none;
  overflow: hidden;
  user-select: text;
  width: 100%;
`;
// END: styled-components

export const OutcomeCard: React.FC<{
  id: string;
  charLimit?: number;
  card: PostItCardFragment & { 
    include?: boolean; 
    index?: number;
    group?: string;
  };
  cards: PostItCardFragment[];
  userId: number;
  userRole: Role | null;
  isActive?: boolean;
  isOverlay?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  updateCard?: (card: any) => Promise<any> | null;
  removeCard?: (id: number) => Promise<any>  | null;
  refetch?: () => void;
  onFocus?: (focused: boolean) => void;
  onBlur?: (processing: boolean) => void;
  readOnly: boolean;
}> = ({
  id,
  card = {} as PostItCardFragment & {
    include?: boolean; 
    index?: number;
    group?: string;
  },
  cards = [],
  userId = null,
  userRole = null,
  isActive = false,
  isOverlay = false,
  loading = false,
  readOnly = false,
  charLimit = 102,
  style = {},
  updateCard = () => null,
  removeCard = () => null,
  refetch = () => null,
  onFocus = () => null,
  onBlur = () => null
}) => {
  // dnd-kit setup
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id, data: card, disabled: loading });

  // ref transformation
  const $style = {
    transform: CSS.Transform.toString({
      ...transform,
      ...(
        isOverlay ? 
          { scaleX: 1.02, scaleY: 1.02 } : 
          { scaleX: 1, scaleY: 1 }
        ),
    } as any),
    transition,
    ...style,
    ...(isActive ? { opacity: .5 } : {})
  };

  const [ mounted, setMounted ] = React.useState(true);
  const [ title, setTitle ] = React.useState(card.title || '');
  const [ focused, setFocused ] = React.useState(false);
  const [ actionsVisible, setActionsVisible ] = React.useState(false);
  const [ showDeleteConfirm, setShowDeleteConfirm ] = React.useState(false);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const isOwner = card?.user?.id === userId;
  const { isLead } = verifyUserRole(userRole);

  /**
   * Check if title exists
   * 
   * @param {string} title
   * 
   * @returns {boolean}
   */
  const isTitleExists = (title: string) => {
    // filter out and remove current card
    const filtered = cards.filter((c: any) => c.id !== card.id);

    // check if title exists
    const exists = filtered.find((c: any) => 
      c.title.trim().toLowerCase() === title.trim().toLowerCase()
      && c.id !== card.id
    );

    return !!exists;
  }

  /**
   * Handle focus event
   * 
   * @returns {void}
   */
  const handleTextFocus = () => {
    onFocus(true);
    setFocused(true);
    setActionsVisible(true);
    updateCard({ ...card, typing: true, typingUserId: userId });
  }

  /**
   * Handle blur event
   * 
   * @returns {Promise<void>}
   */
  const handleTextBlur = async (e: any) => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = 0;
    }

    if (!isLead) {
      return;
    }
    
    // determine if delete button is clicked
    const container = document.getElementById(`card-delete-${card.id}`);
    const isDeleteButton = container?.contains(e.relatedTarget);
    if (!isDeleteButton) {
      setActionsVisible(false);
    }

    onFocus(false);
    setFocused(false);

    // check if title exists across all cards
    const exists = isTitleExists(title);
    // update card
    updateCard({ ...card, typing: false, typingUserId: null });

    // do nothing if title is the same
    if (title && card?.title
      && card?.title?.toLowerCase() === title.toLowerCase()
    ) {
      return;
    }
    
    // if modified title is same as an existing card
    const isSameTitle = exists && card.title;
    if (isSameTitle) {
      setTitle(card.title || '');
      return;
    }

    // if card has title but current title is empty
    if (card.title && !title.trim()) {
      setTitle(card.title);
      return;
    }

    onBlur(true);

    // if card is new, and no title is set or title exists
    if (!title.trim() || (exists && !card.title)) {
      setActionsVisible(false);
      await removeCard(card.id || 0);
      await refetch();
      onBlur(false);
      return;
    }

    // update card
    await updateCard({
      ...card,
      title: isSameTitle ? card.title : title,
      typing: false,
      typingUserId: null
    });

    await refetch();
    onBlur(false);
  }

  /**
   * Handle change event
   * 
   * @param {any} e
   * 
   * @returns {void}
   */
  const handleTextChange = (e: any) => {
    const value = e.target.value.replace('\n', '');
    setTitle(value);
    resizeTextarea();
  }

  /**
   * Handle key press event
   * 
   * @param {any} e
   * 
   * @returns {void}
   */
  const handleTextKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  }

  /**
   * Resize textarea
   * 
   * @returns {void}
   */
  const resizeTextarea = () => {
    if (textareaRef.current) {
      // NOTE: this will force the textarea to resize
      // based on its content...
      textareaRef.current.style.height = '0px';
      textareaRef.current.style.minHeight = '25px';

      
      let scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }

  /**
   * On mount set mounted to true
   * 
   * @returns {void}
   */
  React.useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    }
  }, []);

  /**
   * On mount update states
   * 
   * @returns {void}
   */
  React.useEffect(() => {
    // if not mounted, do nothing
    if (!mounted) {
      return;
    }

    setTitle(card?.title as string);
    resizeTextarea();

    // focus on textarea if card is new
    if (textareaRef.current && !card.title) {
      textareaRef.current.focus();
    }

    // listener for document click
    // outside the delete confirm container
    const onClick = (e: any) => {
      // get delete button container
      const deleteButton = document.getElementById(`card-delete-${card.id}`);
      // get delete confirm container
      const deleteConfirm = document.getElementById(`card-delete-confirm-${card.id}`);

      // if click and is not delete button or delete confirm
      if (deleteConfirm 
        && !deleteConfirm.contains(e.target)
        && deleteButton
        && !deleteButton.contains(e.target)
      ) {
        setShowDeleteConfirm(false);
      }
    }

    // add event listener
    document.addEventListener('click', onClick);

    return () => {
      // remove event listener
      document.removeEventListener('click', onClick);
    }
  }, [card.title, card.id, mounted]);

  return (
    <div ref={setNodeRef} style={$style}>
      <Card 
        active={isActive} 
        overlay={isOverlay}
        disabled={loading && !isOverlay && !focused}
      >
        {card?.include && card?.group === 'includes' && (
          <BodyNormal 
            style={{ marginRight: 10, visibility: isActive ? 'hidden' : 'visible' }} 
            color={colors.purple}
          >
            {(card?.index || 0) + 1}
          </BodyNormal>
        )}

        <CardBody focused={focused} overlay={isOverlay}>
          <CardGrip 
            active={isOverlay}
            {...(!loading ? attributes : {})} 
            {...(!loading ? listeners : {})}
          >
            <Icon name="Grip" color={colors.black} size={30} />
          </CardGrip>

          {card.type !== 'image' && (
            <TextareaWrapper>
              {(!isOwner && typeof userRole === 'string' && !isLead) || readOnly ? (
                <TextOnly>
                  {card.title}
                </TextOnly>
              ) : (
                <Textarea 
                  ref={textareaRef}
                  focused={focused}
                  disabled={!isLead}
                  maxLength={charLimit}
                  spellCheck={false}
                  value={title}
                  onChange={handleTextChange}
                  onFocus={handleTextFocus}
                  onBlur={handleTextBlur}
                  onKeyPress={handleTextKeyPress}
                />
              )}
            </TextareaWrapper>
          )}

          <CardDeleteConfirm id={`card-delete-confirm-${card.id}`} show={showDeleteConfirm}>
            <div>
              <BodyNormal color={colors.black}>
                Delete this outcome?
              </BodyNormal>
            </div>
            <CardDeleteConfirmButtons>
              <ButtonLabel 
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setActionsVisible(false);
                  textareaRef.current?.focus();
                }}
              >
                Cancel
              </ButtonLabel>
              <ButtonLabel
                color={colors.red}
                onClick={async (e) => {
                  e.stopPropagation();

                  setShowDeleteConfirm(false);
                  setActionsVisible(false);
                  onBlur(true);

                  await new Promise((resolve) => setTimeout(resolve, 500));
                  await removeCard(card.id || 0);
                  refetch();
                  onBlur(false);
                }}
              >
                Delete
              </ButtonLabel>
            </CardDeleteConfirmButtons>
          </CardDeleteConfirm>
        </CardBody>

        

        <CardActions id={`card-delete-${card.id}`} visible={actionsVisible}>
          <CardDeleteButton
            level="secondary"
            iconName="Trash"
            size="small"
            onClick={() => {
              if (!title) {
                return;
              }

              setShowDeleteConfirm(true);
              setActionsVisible(false);
            }}
          />
        </CardActions>
      </Card>
    </div>
  )
}