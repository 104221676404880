import React, { useEffect, useState, useRef } from 'react';
import { uid as createUid } from 'uid';
import { UnderlineTextField } from './UnderlineTextField';

interface Props {
  statement: string[];
  onUpdate(v: string[]): void;
  placeholder: string;
  preventAutoFocus?: boolean;
  className?: string;
  style?: React.CSSProperties;
  handleFocus?(): void;
  handleBlur?(): void;
  extraRow?: boolean;
  numbered?: boolean;
  bulletPoint?: boolean;
}

export const BulletPointList = ({
  statement,
  onUpdate,
  placeholder,
  preventAutoFocus,
  className,
  style,
  handleFocus,
  handleBlur,
  extraRow,
  numbered,
  bulletPoint = true,
}: Props) => {
  const associationsContainerRef = React.useRef<HTMLDivElement>(null);

  const [brandAssociationsArray, setBrandAssociationsArray] = useState<
    { text: string; uid: string }[]
  >([]);

  const [focusIdx, setFocusIdx] = useState<null | number>(null);

  const previousFocusIndex = useRef<null | number>(null);

  useEffect(() => {
    //Handle blur event when we leave the container
    function focusOutHandler() {
      // check if the newly focused element is within the container
      setTimeout(function () {
        const wrapper = associationsContainerRef.current;

        const el = document.activeElement?.parentElement as HTMLDivElement;
        if (!wrapper?.contains(el)) {
          handleBlur?.();
        }
      }, 1);
    }

    function focusInHandler() {
      handleFocus?.();
    }
    const wrapper = associationsContainerRef.current;

    if (!!wrapper) {
      wrapper?.addEventListener('focusin', focusInHandler);
      wrapper?.addEventListener('focusout', focusOutHandler);
    }

    return () => {
      wrapper?.removeEventListener('focusin', focusInHandler);
      wrapper?.removeEventListener('focusout', focusOutHandler);
    };
  }, [handleBlur, handleFocus]);

  // when the modal loads, add an empty input at the end
  useEffect(() => {
    if (!statement) return;

    const newArray = statement.map((text) => ({
      text,
      uid: createUid(),
    }));

    if (!newArray.length || extraRow) {
      newArray.push({
        text: '',
        uid: createUid(),
      });
    }

    setBrandAssociationsArray(newArray);

    if (!preventAutoFocus) {
      setFocusIdx(newArray.length - 1);
    }
  }, [statement, preventAutoFocus, extraRow]);

  // when focusIdx is updated, focus on the corresponding textarea element
  useEffect(() => {
    const list = associationsContainerRef.current as HTMLDivElement;
    if (typeof focusIdx !== 'number') return;

    if (!list) return;
    const selectedChild = list.children[focusIdx] as HTMLDivElement;
    if (!selectedChild) return;
    const textarea = selectedChild.children[1] as HTMLTextAreaElement;

    if (previousFocusIndex.current === focusIdx) return;

    previousFocusIndex.current = focusIdx;

    textarea.focus();

    if (!brandAssociationsArray[focusIdx]) return;
    const { text } = brandAssociationsArray[focusIdx];
    if (!text) return;
    const len = text.length;
    textarea.setSelectionRange(len, len);
  }, [brandAssociationsArray, focusIdx, preventAutoFocus]);

  function handleChange(e: React.FormEvent<HTMLTextAreaElement>) {
    const target = e.nativeEvent.target as HTMLTextAreaElement;
    const parentElement = target.parentElement as HTMLDivElement;
    const targetUid = parentElement.id.split('brand-association-')[1];
    const newArray = [...brandAssociationsArray];
    const targetIdx = newArray.findIndex((el) => el.uid === targetUid);
    newArray[targetIdx].text = target.value;

    setBrandAssociationsArray(newArray);

    onUpdate(newArray.map((v) => v.text));
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Backspace') {
      const target = e.nativeEvent.target as HTMLTextAreaElement;
      const parentElement = target.parentElement as HTMLDivElement;
      const targetUid = parentElement.id.split('brand-association-')[1];
      let newArray = [...brandAssociationsArray];
      const targetIdx = newArray.findIndex((el) => {
        return el.uid === targetUid;
      });

      if (e.key === 'Enter') {
        e.preventDefault();
        newArray.splice(targetIdx + 1, 0, { text: '', uid: createUid() });
        setBrandAssociationsArray(newArray);
        setFocusIdx(targetIdx + 1);
        previousFocusIndex.current = null;
      }

      if (e.key === 'Backspace') {
        if (newArray.length > 1 && !newArray[targetIdx].text) {
          e.preventDefault();
          newArray = newArray.filter((_, idx) => idx !== targetIdx);
          setBrandAssociationsArray(newArray);
          setFocusIdx(targetIdx - 1);
          previousFocusIndex.current = null;
        }
      }
    }
  };

  return (
    <div
      data-cy="bullet-point-list"
      ref={associationsContainerRef}
      className={className}
      style={style}
    >
      {brandAssociationsArray?.map(({ text, uid }, idx) => (
        <UnderlineTextField
          id={`brand-association-${uid}`}
          idx={idx + 1}
          key={idx}
          initialValue={text || ''}
          placeholder={placeholder}
          handleChange={handleChange}
          onKeyDown={onKeyDown}
          hideUnderline
          bulletPoint={bulletPoint}
          numbered={numbered}
        />
      ))}
    </div>
  );
};
